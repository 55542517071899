import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import axios from 'axios';

import MainLinkTreePage from 'pages/MainLinkTreePage';
import FeedPage from 'pages/FeedPage';
import FeedViewPage from 'pages/FeedViewPage';
import FeedViewWebPage from 'pages/FeedViewWebPage';
import RouteChangeTracker from './utils/RouteChangeTracker';
import ConfirmPage from './pages/ConfirmPage';
import RedirectPage from './pages/RedirectPage';
import IntroPage from './pages/IntroPage';

function App() {
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
  axios.defaults.withCredentials = true;
  RouteChangeTracker();
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/download" />} />
      {/* redirect */}
      <Route path="/item">
        <Route path=":pk" element={<RedirectPage />} />
      </Route>
      {/* 서대문 */}
      <Route path="/sphh" element={<MainLinkTreePage />} />
      <Route path="/sphh/feed" element={<FeedPage />} />
      <Route path="/sphh/feed/view" element={<FeedViewPage />} />
      {/* 동원 */}
      <Route path="/dongwonfnb" element={<MainLinkTreePage />} />
      {/* 토니모리 */}
      <Route path="/tonymoly" element={<MainLinkTreePage />} />
      {/* 파스쿠찌 */}
      <Route path="/pascucci" element={<MainLinkTreePage />} />
      {/* 루이까또즈 */}
      <Route path="/louisquatorze" element={<MainLinkTreePage />} />
      {/* 삼립 샐러드 */}
      <Route path="/samlip" element={<MainLinkTreePage />} />
      {/* 사조뽀로로 샐러드 */}
      <Route path="/bebecook" element={<MainLinkTreePage />} />
      {/* 비비큐 샐러드 */}
      <Route path="/bbq" element={<MainLinkTreePage />} />
      {/* 오프라이스 샐러드 */}
      <Route path="/oprice" element={<MainLinkTreePage />} />
      {/* 모나리자 */}
      <Route path="/monna" element={<MainLinkTreePage />} />
      {/* 2022 kif*/}
      <Route path="/2022kif" element={<MainLinkTreePage />} />
      <Route path="/2022kif/view" element={<FeedViewWebPage />} />
      {/* 2022 mhsc*/}
      <Route path="/2022mhsc" element={<MainLinkTreePage />} />
      {/* 고디바 */}
      <Route path="/godiva" element={<MainLinkTreePage />} />
      {/* waterg */}
      <Route path="/waterg" element={<MainLinkTreePage />} />
      {/* bts_guess */}
      <Route path="/bts_guess" element={<MainLinkTreePage />} />
      {/* bts_guess */}
      <Route path="/isaac" element={<MainLinkTreePage />} />
      {/* kgcshop 정관장 */}
      <Route path="/kgcshop" element={<MainLinkTreePage />} />
      {/* 정관장 200001 */}
      <Route path="/200001" element={<MainLinkTreePage />} />
      {/* 정관장  200002 */}
      <Route path="/200002" element={<MainLinkTreePage />} />
      {/* incellderm */}
      <Route path="/incellderm_official" element={<MainLinkTreePage />} />
      {/*<<<<<<<<<<<<<<<<<<<<<<책자>>>>>>>>>>>>>>>>>>>>>>>> */}
      {/* incellderm */}
      <Route path="/chanel" element={<MainLinkTreePage />} />
      {/* 롯데 상품권 */}
      <Route path="/lotteshopping" element={<MainLinkTreePage />} />
      {/* pfzer */}
      <Route path="/pfizer" element={<MainLinkTreePage />} />
      {/* mamonde */}
      <Route path="/mamonde" element={<MainLinkTreePage />} />
      {/* spicyRamen */}
      <Route path="/spicyRamen" element={<MainLinkTreePage />} />
      {/* samsung_buds */}
      <Route path="/samsung_buds" element={<MainLinkTreePage />} />
      {/* penmax */}
      <Route path="/penmax" element={<MainLinkTreePage />} />
      {/* marvel */}
      <Route path="/marvel" element={<MainLinkTreePage />} />
      {/* cjlogistics */}
      <Route path="/cjlogistics" element={<MainLinkTreePage />} />
      {/* vangoghmuseum */}
      <Route path="/vangoghmuseum" element={<MainLinkTreePage />} />
      {/* mobis */}
      <Route path="/mobis" element={<MainLinkTreePage />} />
      {/* muzik */}
      <Route path="/muzik" element={<MainLinkTreePage />} />
      {/* louvre */}
      <Route path="/louvre" element={<MainLinkTreePage />} />
      {/* gwanak */}
      <Route path="/gwanak" element={<MainLinkTreePage />} />
      {/* <<<<<<<<<<<<<<<<<<<<<<진품, 가품 테스트>>>>>>>>>>>>>>>>>>>>>>>> */}
      {/* 까르띠에 100001*/}
      <Route path="/100001" element={<MainLinkTreePage />} />
      {/* 까르띠에 100002 */}
      <Route path="/100002" element={<MainLinkTreePage />} />
      {/* 까르띠에 000001 */}
      <Route path="/000001" element={<MainLinkTreePage />} />
      {/* 까르띠에 000002 */}
      <Route path="/000002" element={<MainLinkTreePage />} />
      {/* logen */}
      <Route path="/logen001" element={<MainLinkTreePage />} />
      <Route path="/logen002" element={<MainLinkTreePage />} />
      <Route path="/logen003" element={<MainLinkTreePage />} />
      {/* looxLooe */}
      <Route path="/110001" element={<MainLinkTreePage />} />
      <Route path="/110002" element={<MainLinkTreePage />} />
      {/* baba */}
      <Route path="/120001" element={<MainLinkTreePage />} />
      <Route path="/120002" element={<MainLinkTreePage />} />
      {/* incellderm_official */}
      <Route path="/incellderm_official" element={<MainLinkTreePage />} />
      <Route path="/theoriginal" element={<MainLinkTreePage />} />
      <Route path="/whoau" element={<MainLinkTreePage />} />
      <Route path="/tamnaales" element={<MainLinkTreePage />} />

      {/* lotte Department mall */}
      <Route path="/lottemall" element={<MainLinkTreePage />} />
      {/*  celltrion  */}
      <Route path="/celltrion" element={<MainLinkTreePage />} />
      {/*  amorepacific  */}
      <Route path="/amorepacific" element={<MainLinkTreePage />} />
      {/*  samyang  */}
      <Route path="/samyang" element={<MainLinkTreePage />} />
      {/*  samsung  */}
      <Route path="/samsung" element={<MainLinkTreePage />} />
      {/* alpha */}
      <Route path="/alpha" element={<MainLinkTreePage />} />
      {/* cgv */}
      <Route path="/cgv" element={<MainLinkTreePage />} />
      {/* hyundai */}
      <Route path="/hyundai" element={<MainLinkTreePage />} />
      {/* Kobaco */}
      <Route path="/kobaco" element={<MainLinkTreePage />} />
      {/* urfuturebook*/}
      <Route path="/urfuture" element={<MainLinkTreePage />} />
      {/* urcheck*/}
      <Route path="/urcheck" element={<MainLinkTreePage />} />
      {/* interparkticket */}
      <Route path="/interparkticket" element={<MainLinkTreePage />} />
      {/* isaactoast */}
      <Route path="/isaactoast" element={<MainLinkTreePage />} />
      {/* goverment24 */}
      <Route path="/gov24" element={<MainLinkTreePage />} />
      {/* shinmyung */}
      <Route path="/shinmyung" element={<MainLinkTreePage />} />
      {/* shinmyung 300001 */}
      <Route path="/300001" element={<MainLinkTreePage />} />
      {/* shinmyung 300002 */}
      <Route path="/300002" element={<MainLinkTreePage />} />
      {/*    글림미디어    */}
      <Route path="/gleammedia/:urCode" element={<MainLinkTreePage />} />
      <Route path="/kgcshop/:urCode" element={<MainLinkTreePage />} />
      <Route path="/kgctemp/:urCode" element={<MainLinkTreePage />} />
      <Route path="/happyhabit" element={<MainLinkTreePage />} />
      <Route path="/louisvuitton/:urCode" element={<MainLinkTreePage />} />
      <Route path="/gucci/:urCode" element={<MainLinkTreePage />} />
      <Route path="/KTNG/:urCode" element={<MainLinkTreePage />} />
      {/* <<<<<<<<<<<<<<<<<<<<<<진품, 가품 테스트>>>>>>>>>>>>>>>>>>>>>>>> */}
      {/* indonesia */}
      <Route path="/indonesia" element={<ConfirmPage />} />
      {/* ssg_gift */}
      <Route path="/ssg_gift" element={<ConfirmPage />} />
      {/* incellderm */}
      <Route path="/incellderm" element={<ConfirmPage />} />
      {/*    Ur 다운로드 페이지    */}
      <Route path="/download" element={<IntroPage />} />
      <Route path="/upa/:urCode" element={<MainLinkTreePage />} />
    </Routes>
  );
}

export default App;

import React, { FC, ReactNode } from 'react';
import { LayoutContainer, URlogo, SideWhiteSpace } from './styles';
import SocialList from 'shared/SocialList';
import Profile from 'shared/Profile';
import UrLogo from 'assets/icons/URlogo.svg';
import { useLocation } from 'react-router-dom';

interface Props {
  children: ReactNode;
  data: any;
  /*  snsList?: any;
    profile?: any;
    bgImg?: any;
    theme?: any;*/
}

const Layout: FC<Props> = ({
  children,
  data,
  // snsList, profile, bgImg, theme
}) => {
  const location = useLocation();
  const pathname = location.pathname;
  const urCodePath = ['gleammedia', 'kgcshop', 'KTNG', 'louisvuitton', 'gucci', 'upa', 'kgctemp'];
  return (
    <LayoutContainer
      bgImg={data.theme.backgroundImage}
      className={`${data.theme.themeName === 'kiff' || data.theme.themeName === 'mhsc' ? 'kiff' : ''}`}
    >
      <Profile profile={data.profile} themeName={data.theme.themeName} urCodePath={urCodePath} />
      <SideWhiteSpace
        className={`${data.theme.themeName === 'sphh' ? 'sphh' : ''} ${
          data.theme.themeName === 'dongwon' ? 'dongwon' : ''
        } ${
          pathname === '/100001' ||
          pathname === '/100002' ||
          pathname === '/000001' ||
          pathname === '/000002' ||
          pathname === '/200001' ||
          pathname === '/200002' ||
          pathname === '/110001' ||
          pathname === '/110002' ||
          pathname === '/120001' ||
          pathname === '/120002' ||
          pathname === '/300001' ||
          pathname === '/300002' ||
          (urCodePath.indexOf(pathname.split('/')[1]) !== -1 &&
            pathname.split('/').length === 3 &&
            pathname.split('/')[2] !== '')
            ? 'bigName'
            : ''
        }
        
        
        
        `}
      >
        {children}
        {data.theme.themeName === 'dongwon' && (
          <SocialList snsList={data.snsList} themeName={data.theme.themeName} nickName={data.profile.nickname} />
        )}
      </SideWhiteSpace>
      {data.theme.themeName === 'sphh' && (
        <SocialList snsList={data.snsList} themeName={data.theme.themeName} nickName={data.profile.nickname} />
      )}
      {/*{theme.theme.themeName !== 'dongwon' ||*/}
      {/*  (theme.theme.themeName !== 'kiff' && (*/}
      {/*    <SocialList snsList={snsList.snsList} themeName={theme.theme.themeName} />*/}
      {/*  ))}*/}
      {data.theme.themeName !== 'sphh' && <URlogo src={UrLogo} alt="URlogo" />}
    </LayoutContainer>
  );
};

export default Layout;
